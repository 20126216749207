.contact {
  min-height: auto;
  background: var(--second-bg-color);
}
.contact form{
  max-width: 50rem;
  margin: 0 auto;
}
.contact form .form__box {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.contact form .form__box  .form__group{
  display: flex;
  flex-direction: column;
  gap: .5rem;
  position: relative;
  width: 49%;
  margin: .5rem 0;
}


.contact form .form__box .form__group input,
.contact form .form__textarea textarea {
  width: 100%;
  height: 100%;
  padding: .5rem;
  font-size: 1rem;
  color: var(--text-color);
  background: transparent;
  border-radius: .375rem;
  border: .125rem solid var(--main-color);
}
.contact form .form__box .form__input input::placeholder,
.contact form .form__textarea textarea::placeholder {
  color: var(--text-color);
}

.contact form .form__textarea {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  position: relative;
  margin: 1rem 0 1rem;
}
.contact form .form__textarea textarea {
  resize: none;
}
.contact form .form__checkbox {
  margin-bottom: 1rem;
}
.form__btn {
  position: relative;
  width: 14rem;
  height: 3.125rem;
}
.form__btn-submit {
  height: 100%;
  width: 100%;
  background: var(--main-color);
  border: 0.125rem solid var(--main-color);
  border-radius: .5rem;
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: .0625rem;
  color: var(--bg-color);
  z-index: 1;
  overflow: hidden;
  transition: .5s;
}

.form__btn-submit:hover {
  color: var(--main-color);
  background: var(--second-bg-color);
}

.form__checkbox-hidden {
  visibility: hidden;
  background: transparent;
  width: 100%;
  height: 0;
  color: red;
  font-size: 1rem;
  margin-left: 1.4rem;
  margin-top: .4rem;
}

/*для временного блока Контакты*/

.contact__block {
  display: grid;
  line-height: 1.3;
  justify-content: center;
  margin: 0 auto;
  font-size: 1.875rem;
  padding: 1.25rem;
}
.contact__item {
  padding-bottom: .625rem;
  text-decoration: none;
  color: #fff;
  &:hover {
    color: var(--main-color);
  }
}
.bx-envelope, .bx-phone, .bx-map {
  color: var(--main-color);
}
.form__input {
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
& a {
  color: white;
  text-decoration: underline;
  transition: .3s;
}
  & a:hover {
    color: var(--main-color);
  }
}

.form-label {
  position: absolute;
  pointer-events: none;
  left: .675rem;
  top: .5469rem;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.5625rem;
  padding-left: .625rem;
  font-size: .9375rem;
  transition: .3s;
}
.form-input:focus~.form-label,
.form-input:not(:focus):valid~.form-label {
  top: -5px;
  font-size: .625rem;
  //color: rgba(255, 255, 255, .5);
  color: var(--main-color);
}