.logo{
  &__set{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: .625rem;
    align-items: center;
  }
  &__svg{
    //display: inline-block;
    height: 1.875rem;
  }
}
.header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1.25rem 9%;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  transition: .3s;
  &.sticky {
    background: var(--bg-color);
  }
}
.main-menu__list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  list-style: none;
}
.main-menu__link{
  font-size: 1.063rem;
  color: var(--text-color);
  font-weight: 500;
  margin-left: 2.1875rem;
  transition: 3s;
}
.main-menu a:hover,
.main-menu a.active {
  color: var(--main-color);

}
#menu-icon{
  position: relative;
  font-size: 2.25rem;
  color: var(--text-color);
  cursor: pointer;
  display: none;
}