.price {
  padding-bottom: 3.75rem;
  background: var(--second-bg-color);
  min-height: auto;

  &__grid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3em;
  }
}
.price-card {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 3em 1em;
  border: 0.125rem solid var(--main-color);
  border-radius: 0.375rem;
  margin-bottom: 1.25rem;
  overflow: hidden;
  max-width: 33%;
  text-align: center;
  align-items: center;
  transition: all .5s ease-in-out;
  &:hover {
    transform: scale3d(1.1, 1.1, 1.1);
    background: var(--bg-color);
    & span {
      position: absolute;
      display: block;
    }
    & span:nth-child(1) {
      top: 0;
      left: -100%;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, transparent, #03e9f4);
      animation: btn-anim1 1s linear infinite;
    }
    & span:nth-child(2) {
      top: -100%;
      right: 0;
      width: 2px;
      height: 100%;
      background: linear-gradient(180deg, transparent, #03e9f4);
      animation: btn-anim2 1s linear infinite;
      animation-delay: .5s
    }
    & span:nth-child(3) {
      bottom: 0;
      right: -100%;
      width: 100%;
      height: 2px;
      background: linear-gradient(270deg, transparent, #03e9f4);
      animation: btn-anim3 1s linear infinite;
      animation-delay: .5s
    }
    & span:nth-child(3) {
      bottom: -100%;
      left: 0;
      width: 2px;
      height: 100%;
      background: linear-gradient(360deg, transparent, #03e9f4);
      animation: btn-anim4 1s linear infinite;
      animation-delay: .75s
    }
  }
  &__heading {
    font-size: 1.875rem;
    padding-bottom: 1em;
  }
  &__amount {
    font-size: 3.125rem;
    color: var(--main-color);
    padding-bottom: 1em;
  }
  &__terms {
    list-style-type: none;
    text-align: center;
    font-size: 1.25rem;
    padding: 0;
  }
}

.term__item {
  padding: 0 .625rem .625rem;
}
.price-btn {
  justify-content: center;
}