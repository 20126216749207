$fa-font-path           : "../fonts";
:root{
  --bg-color: #00042A;
  --second-bg-color: #141942;
  --text-color: #ededed;
  --main-color: #38ACF3;

  --impulse-main-color:#29308B;
  --impulse-grad-1:#2C338D;
  --impulse-grad-2:#3497B7;
  --impulse-gradient: linear-gradient(to right,  var(--impulse-grad-1),var(--impulse-grad-2));

  --section-gray-bg: #f8f9ff;
  --section-padding-y: 3rem;
  --date-size: 2rem;
  //--main-color: #21a649;
  --card-bg: #fff;
  --card-radius: .5rem;
  --grad-color-1: #348F50;
  --grad-color-2: #2f829d;
  --gray-line-color: #E9ECEF;
  --card-gray: #f6f9fc;
  --error-color: #e91e63;
  --success-color: #21a649;
  --grad-color-transparent-1: rgba(52,143,80,.85);
  --grad-color-transparent-2: rgba(47,130,157,.85);
  --grad-color-bright-1: #cdf7d0;
  --grad-color-bright-2: #ace5f7;
  --grad-color-dark-1: #248843;
  --grad-color-dark-2: #35b05b;
  --heading-color: #0A3340;
  //--text-color: #425766;
  --large-letter-spacing: .025em;
  --text-line-height: 1.5;
  --heading-line-height: 1.25;
  --heading-min-line-height:1;
  --link-color: #0080A7;
  --link-color-hover: #005760;
  --edu-dep-icon-size: 2rem;
  --program-code-size: 2em;
  --fast-nav-icon-size: 4rem;
  --stop-color-1: #21a649;
  --stop-color-2: #2f829d;
  --stop-color-3: #00FF19;
  --stop-color-4: #00AE11;
  --shadow-sm: 0 2px 3px rgb(0 0 0 / 8%);
  --footer-bg: linear-gradient(to right, #348F50, #2f829d);
  --shadow-level-1: 0 0.125em 0.313em rgba(10, 37, 64, .09), 0 0.063em 0.125em rgba(0, 0, 0, .07);
  --shadow-level-2: 0 0.250em 0.375em rgba(10, 37, 64, .09), 0 0.063em 0.188em rgba(0, 0, 0, .08);
  --shadow-level-3: 0 0.063em 0.313em 0 rgba(10, 37, 64, .07), 0 0.438em 1.063em 0 rgba(0, 0, 0, .1);
  --shadow-level-4: 0 0.938em 1.5625em rgba(10, 37, 64, .1), 0 0.313em 0.938em rgba(0, 0, 0, .07);
  --shadow-level-5: 0 1.25em 2.5em rgba(10, 37, 64, .15), 0 0.313em 0.938em rgba(0, 0, 0, .1);
  --testimonial-img-size: 120px;
  --testimonial-circle-size: 2rem;
  --fast-nav-radius: .5rem;
  --main-grad: linear-gradient(to right, var(--grad-color-1), var(--grad-color-2));
  --blured-grad: linear-gradient(to right, var(--grad-color-transparent-1), var(--grad-color-transparent-2));
  --bright-grad: linear-gradient(to right, var(--grad-color-bright-1), var(--grad-color-bright-2));
  --dark-grad: linear-gradient(to right, var(--grad-color-dark-1), var(--grad-color-dark-2));
  --catalogue-item-hover-color: #017698;
  --catalogue-item-font-size: .875rem;
  --catalogue-item-padding: 1rem;
  --catalogue-block-max-width: 800px;
  --footer-text-color: rgba(255, 255, 255, 0.8);
  --footer-text-color-hover: rgba(255, 255, 255, 1);
  --footer-social-bg: rgba(255, 255, 255, .1);
  --footer-social-size: 2em;
  --footer-fix-letterspacing: .025em
}