/*BREAKPOINTS*/
@media (max-width: 1200px) {
  html {
    font-size: 92.8%;
  }

  .instruction__grid {
    grid-template-columns: repeat(1,1fr)!important;
  }
  .price-card .btn-box {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .header {
    padding: 1.25rem 4%;
  }
  .section {
    padding: 6.25rem 4% 1.25rem;
  }
  .home {
    padding: 0 4%;
  }
  .home-content__heading {
    font-size: 2.5rem;
  }
  .about__block {
    grid-template-columns: repeat(1, 1fr);
  }
  .instruction__grid {
    grid-template-columns: repeat(1,1fr)!important;
  }

  .footer {
    padding: 1.25rem 4%;
  }
}
@media (max-width: 768px) {
  .header {
    background: var(--bg-color);
  }
  #menu-icon {
    display: block;
  }
  .main-menu {
    position: absolute;
    top: 100%;
    left: -100%;
    width: 100%;
    padding: .625rem 4%;
    background: var(--main-color);
    box-shadow: 0 .3125rem .625rem rgba(0, 0, 0, .2);
    z-index: 1;
    transition: .25s ease;
    transition-delay: .25s;
  }
  .main-menu.active {
    left: 0;
    transition-delay: 0s;
  }
  .main-menu.active .main-menu__list {
    display: flex;
    flex-direction: column;
  }
  .main-menu .active-nav {
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: var(--bg-color);
    border-top: .0625rem solid rgba(0, 0, 0, .2);
    z-index: -1;
    transition: .25s ease;
    transition-delay: 0s;
  }
  .main-menu.active .active-nav{
    transition-delay: .25s;
    left: 0;
  }
  .main-menu__link{
    display: block;
    font-size: 1.25rem;
    margin: 1rem 0;
    transform: translateX(-12.5rem);
    transition: .25s ease;
    transition-delay: 0s;
  }
  .main-menu.active .main-menu__link {
    transform: translateX(0);
    transition-delay: .25s;
  }
  .home-imgHover {
    background: var(--bg-color);
    opacity: .6;
  }
  .home-content__heading {
    font-size: 2rem;
  }
.instruction__mockup {
  width: 90%;
}
.price__grid {
display: flex;
  flex-direction: column;

  }
.price-card {
    max-width: 100%;
  }
  //.price-card .price-btn {
  //  margin-left: 27%;
  //}
}
@media (max-width: 520px) {
  html {
    font-size: 50%;
  }
  .home-content .home-content__heading {
    display: flex;
    flex-direction: column;
  }
  .about-block {
  max-width: 450px;
  }

  .rid-box img {
    max-width: 100%;
  }
  .instruction-desc {
    max-width: 450px;
    margin: 0 auto!important;
  }
  .instruction__mockup {
    transform: scale3d(.7, .7, .7);
  }
  .home-sci {
    width: 90px;
  }
  .home-sci a {
    width: 38px;
    height: 38px;
  }
}
@media (max-width: 436px) {
  .home-content .home-content__heading {
    font-size: 3.25rem;
  }
  .home-content .text-animate h3 {
    font-size: 1.6875rem;
  }
  .opportunities {
    padding: 6.25rem 4% 3.125rem 5%;
  }

  .about-content .about-content__heading {
    font-size: 1.25rem;
  }
  .instruction-desc {
    max-width: 400px;
  }
  .instruction__mockup {
    transform: scale3d(.7, .7, .7);
  }
  .contact form .input-box .input-field {
    width: 100%;
  }
  .footer {
    flex-direction: column-reverse;
  }
  .footer .footer-text {
    margin-top: 1.25rem;
    text-align: center;
  }
}
@media (max-width: 371px) {
  .home {
    justify-content: center;
  }
  .home-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .home-content .home-content__heading {
    font-size: 3.125rem;
  }
  .instruction-desc {
    max-width: 300px;
  }
  .instruction-desc .btn-box {
    justify-content: flex-start;
    gap: 1.25rem;
    width: 95%;
  }
  .instruction__mockup {
    transform: scale3d(.5, .5, .5);
  }

  .form__checkbox-hidden {
    width: 50%;
    height: 200%;
  }
}
