.footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 1.25rem 9%;
  //background: var(--second-bg-color);
}
.footer-text p, .footer-text a {
  font-size: 1rem;
  margin: 0;
  padding: 0;
}
.footer-text .privacy-policy {
  color: var(--text-color);
  font-size: 1rem;
}

.footer-contact {
  display: flex;
  flex-direction: column;
  gap: .625rem
}

.footer-iconTop a {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  background: var(--main-color);
  border: .125rem solid var(--main-color);
  border-radius: .375rem;
  z-index: 1;
  overflow: hidden;
}
.footer-iconTop a::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--second-bg-color);
  z-index: -1;
  transition: .5s;
}
.footer-iconTop a:hover::before {
  width: 100%;
}
.footer-iconTop a i {
  font-size: 1.5rem;
  color: var(--bg-color);
  transition: .5s;
}
.footer-iconTop a:hover i {
  color: var(--main-color);
}

.animate {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: var(--bg-color);
  z-index: 98;
}
.animate.home-img {
  width: 50%;
}

.opportunities .opportunities-box .animate.scroll {
  width: 105%;
}

/*social icons*/
.home-sci{
  position: absolute;
  bottom: 2.5rem;
  width: 100px;
  display: flex;
  justify-content: space-between;
}
.home-sci a{
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: transparent;
  border: .125rem solid var(--main-color);
  border-radius: 50%;
  font-size: 20px;
  color: var(--main-color);
  z-index: 1;
  overflow: hidden;
  transition: .5s;
}
.home-sci a:hover{
  color: var(--bg-color);
}
.home-sci a::before{
  content: '';
  position: absolute;
  top:0;
  left:0;
  width: 0;
  height: 100%;
  background: var(--main-color);
  z-index: -1;
  transition: .5s;
}
.home-sci a:hover::before{
  width: 100%;
}