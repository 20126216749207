@keyframes homeBgText {
  0%,10%,100% {
    background-position: -34rem 0;
  }
  65%,85% {
    background-position: 0 0;
  }
}
@keyframes homeCursorText {
  0%,10%,100% {
    width: 0;
  }
  65%,78%,85% {
    width: 100%;
    opacity: 1;
  }
  75%,81% {
    opacity: 0;
  }
}
@keyframes aboutSpinner {
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

//animation price-card

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}
@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}
@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}


