.home{
  display: flex;
  align-items: center;
  padding: 0 9%;
  background: url('/img/homeoriginal_small.jpg') no-repeat;
  background-size: cover;
  background-position: center;
}
.home-content{
  max-width: 50rem;
  z-index: 99;
}
.home-content__heading {
  position: relative;
  display: inline-block;
  font-size: 3.125rem;
  font-weight: 500;
  line-height: 1.1;
  padding-bottom: .5469rem;
  margin: 0;
}
.home-content__heading span {
  color: var(--main-color);
  font-size: 3.75rem;
  font-weight: 700;
}
.home-content .text-animate{
  position: relative;
  width: 34.0625rem;
}
.home-content .text-animate .home-content__accent {
  margin: 0;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.3;
  color: transparent;
  -webkit-text-stroke: .7px var(--main-color);
  background-image: linear-gradient(var(--main-color), var(--main-color));
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  background-position: -34.375rem 0;
  animation: homeBgText 6s linear infinite;
  animation-delay: 2s;
}
.home-content .text-animate .home-content__accent::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  border-right: 2px solid var(--main-color);
  z-index: -1;
  animation: homeCursorText 6s linear infinite;
  animation-delay: 2s;
}
.home-content .home-addinfo {
  position: relative;
  font-size: 1rem;
  line-height: 1.4;
  margin: 1.25rem 0 2.5rem;
  max-width: 80%;
}
.btn-box{
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 28.75rem;
  height: 3.125rem;
}
.btn-box .btn{
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 13.125rem;
  height: 100%;
  background: var(--main-color);
  border: .125rem solid var(--main-color);
  border-radius: .5rem;
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: .0625rem;
  color: var(--bg-color);
  z-index: 1;
  overflow: hidden;
  transition: .5s;
}
.btn-box .btn:hover{
  color: var(--main-color);
}
.btn-box .btn:nth-child(2){
  background: transparent;
  color: var(--main-color);
}
.btn-box .btn:nth-child(2):hover{
  color: var(--bg-color);
}
.btn-box .btn:nth-child(2)::before{
  background: var(--main-color);
}
.btn-box .btn::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--bg-color);
  z-index: -1;
  transition: .5s;
}
.btn-box .btn:hover::before{
  width: 100%;
}
.home-imgHover{
  position: absolute;
  top: 0;
  right: 0;
  width: 50.3%;
  height: 90%;
  background: transparent;
  transition: 3s;
}
.home-imgHover:hover{
  background: var(--bg-color);
  opacity: .8;
}