.documents {
  min-height: 50%;
  &__block {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 1.25rem;
    width: 65%;
    margin: 0 auto;
  }
  &__item {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    position: relative;
    padding: 1rem;
    border: 0.125rem solid var(--main-color);
    border-radius: 0.375rem;
    margin-bottom: 1.25rem;
    overflow: hidden;
  }
  &__item i {
    font-size: 2.5rem;
    color: var(--main-color);
  }
}
.tree-bg {
  position: absolute;
  left: 30%;
  top: -30%;
  z-index: -1;
}
.document__link {

  color: #fff;
  transition: .3s ease-in-out;
}
.document__link:hover {
  color: var(--main-color);
}