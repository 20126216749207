.services {
  background: var(--second-bg-color);
}
.services-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.25rem;
  &__card {
    flex: 1 1 37.5rem;
    background: var(--bg-color);
    padding: 1.875rem 1.25rem 2.5rem;
    border-radius: .375rem;
    //text-align: center;
    border: .125rem solid var(--second-bg-color);
    transition: .5s ease;
  }
  &__card:hover {
    border-color: var(--main-color);
    transform: scale(1.02);
  }
}
.services-box__card .card__title {
  font-size: 1.5rem;
  color: var(--main-color);
}