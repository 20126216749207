.timeline {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: auto;
  padding-bottom: 3.125rem;
}

.timeline ul {
  padding: 50px 0;
}
.timeline ul li {
  background: var(--main-color);
  position: relative;
  margin: 0 auto;
  width: 3px;
  padding-bottom: 40px;
  list-style-type: none;
}
.timeline ul li:last-child {
  padding-bottom: 7px;
}
.timeline ul li:before {
  content: "";
  background: var(--main-color);
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  border: 3px solid var(--main-color);
  border-radius: 50%;
}
.timeline ul li .hidden {
  opacity: 0;
}
.timeline ul li .content {
  position: relative;
  top: 7px;
  width: 450px;
  padding: 20px;
}
.timeline ul li .content h2 {
  color: var(--main-color);
  font-size: 2.5rem;
  padding-bottom: .5em;
  text-align: center;
}
.timeline ul li .content p {
  text-align: center;
}
.timeline ul li .content:before {
  content: "";
  background: var(--main-color);
  position: absolute;
  top: 0;
  width: 38px;
  height: 3px;
}
.timeline ul li:nth-child(odd) .content {
  left: 50px;
  background: var(--bg-color);
  outline: 3px solid var(--main-color);
  outline-offset: -3px;
}
.timeline ul li:nth-child(odd) .content:before {
  left: -38px;
}
.timeline ul li:nth-child(even) .content {
  left: calc(-450px - 45px);
  background: var(--bg-color);
  outline: 3px solid var(--main-color);
  outline-offset: -3px;
}
.timeline ul li:nth-child(even) .content:before {
  right: -38px;
}

/* -------------------------
   ----- Media Queries -----
   ------------------------- */
@media screen and (max-width: 1020px) {
  .timeline ul li .content {
    width: 41vw;
  }

  .timeline ul li:nth-child(even) .content {
    left: calc(-41vw - 45px);
  }
}
@media screen and (max-width: 700px) {
  .timeline ul li {
    margin-left: 20px;
  }
  .timeline ul li .content {
    width: calc(100vw - 100px);
  }
  .timeline ul li .content h2 {
    text-align: initial;
  }
  .timeline ul li:nth-child(even) .content {
    left: 45px;

  }
  .timeline ul li:nth-child(even) .content:before {
    left: -33px;
  }
}