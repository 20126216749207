.about{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: var(--second-bg-color);
  padding-bottom: 3.75rem;
&__block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3.75rem;}
&__block-right {
    margin: auto;
    }
  }
span{
  color: var(--main-color);
}
.about-img{
  margin: 3.125rem auto;
  position: relative;
  width: 15.625rem;
  height: 15.625rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  & .circle-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-top: .125rem solid var(--second-bg-color);
    border-bottom: .125rem solid var(--second-bg-color);
    border-left: .125rem solid var(--main-color);
    border-right: .125rem solid var(--main-color);
    animation: aboutSpinner 8s linear infinite;
  }
}
.about-img img{
  width: 90%;
  border-radius: 50%;
  border: .125rem solid var(--main-color);
}
.about-content {
  & h3{
    position: relative;
    display: inline-block;
    font-size: 1.625rem;
    margin: 0;
  }
  & p{
    position: relative;
    font-size: 1rem;
    margin: 1.25rem 0;
    text-align: left;
  }
  & p:nth-child(3){
    margin-bottom: 0;
  }
}
.about-list{
  font-size: 1rem;
  &__item{
    &::marker{
      color: var(--main-color);
      font-size: 1.125em;
    }

    margin-bottom: .5469rem;
    margin-left: -1rem;
  }
}
.btn-box.btns {
  display: inline-block;
  width: 9.375rem;
}
.btn-box.btns a::before {
  background: var(--second-bg-color);
}
.accent {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.3;
}
.logo-svg {
  margin-top: 4.375rem;
  margin-left: 6rem;
  margin-bottom: -4.375rem;
}

/*свидетельство*/

.rid-box {
  position: relative;
  border-radius: 1.25rem;
  box-shadow: 0 0 .625rem var(--bg-color);
  overflow: hidden;
  transform: scale3d(.7, .7, .7);
}
.rid-box img {
  transition: .5s ease;
}
.rid-box:hover img {
  transform: scale(1.1);
}
.rid-box .rid-layer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0,0,0,.1), var(--bg-color));
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 2.5rem;
  transform: translateY(100%);
  transition: .5s ease;
}
.rid-box:hover .rid-layer {
  transform: translateY(0);
}
.rid-layer-desc {
  font-size: 1rem;
  margin: .1875rem 0 2.5rem;
}