*{

  box-sizing: border-box;
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;

}
html {
  //font-size: .625rem;
}

html,body{
  width: 100%;
  height: 100%;
}
body{
  font-family: 'Roboto', 'Arial', sans-serif;
  background: var(--bg-color);
  color: var(--text-color);
  margin: 0;
}
.heading{
  position: relative;
  font-size: 3.125rem;
  margin-bottom: 1.875rem;
  text-align: center;
}
p {
  line-height: 1.4;
}