.instruction {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: auto;
  &__grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: .625rem;
    grid-row-gap: .625rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1.5fr;
  }
  &__text {
    font-size: 1rem;
    line-height: 1.4;

  }
  &__list {
    font-size: 1rem;
    line-height: 1.4;
    padding-bottom: 1.25rem;
    padding-left: 1.25rem;
  }
  &__item {
    padding-bottom: .625rem;
    &::marker {
      color: var(--main-color);
    }
  }
}

.site-link {
  color: var(--main-color);
}
.instruction-desc {
  margin: auto 0;
}
.instruction-docs {
  font-size: .3125rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: .625rem;
}
.instruction-docs__link {
  font-size: .625rem;
  color: #fff;
}
.bx-download {
  font-size: 1.25rem;
  color: var(--main-color);
  &:hover{
    color: #fff;
    transform: scale3d(1.2, 1.2, 1.2);
  }
}
