.opportunities{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: auto;
}
.opportunities-row {
  display: flex;
  flex-wrap: wrap;
  gap: 3.125rem;
}
.opportunities-column {
  flex: 1 1 25rem;
  &__title {
    position: relative;
    display: inline-block;
    font-size: 1.5rem;
    margin: 0 0 1rem 1.25rem;
  }
}
.opportunities-box {
  position: relative;
  border-left: .125rem solid var(--main-color);
}
.opportunities-content {
  position: relative;
  padding-left: 1.25rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -.6875rem;
    width: 1.25rem;
    height: 1.25rem;
    background: var(--main-color);
    border-radius: 50%;
  }

  &__card {
    position: relative;
    padding: .9375rem;
    border: .125rem solid var(--main-color);
    border-radius: .375rem;
    margin-bottom: 1.25rem;
    overflow: hidden;
  }

  &__card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: var(--second-bg-color);
    z-index: -1;
    transition: .5s;
  }

  &__card:hover::before {
    width: 100%;
  }
}
.opportunities-content__card .card__title {
  font-size: 1.5rem;
  color: var(--main-color);
  padding-bottom: .3rem;
}
.card__title .card__title-icon {
  font-size: 2rem;
  padding-right: .3rem;
}

.opportunities-content__card .card-desc {
  margin: .5rem;
  font-size: 1rem;
}